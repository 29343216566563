import "./styles.css";

import { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import React from "react";
import xbgall from "../../img/instructions/xbgall.jpg";
import android_1 from "../../img/instructions/android/android_1.jpg";
import android_2 from "../../img/instructions/android/android_2.jpg";
import android_3 from "../../img/instructions/android/android_3.jpg";

import ios_1 from "../../img/instructions/ios/ios_1.jpg";
import ios_2 from "../../img/instructions/ios/ios_2.jpg";
import ios_3 from "../../img/instructions/ios/ios_3.jpg";
import ButtonHead from "../buttonHead";

const InstructionsPage = () => {
  const navigate = useNavigate();
  useEffect(() => {}, []);

  return (
    <div className="wrapper">
      <ButtonHead />
      {/* <h1>Всегда под рукой</h1> */}
      <h2 className="faq-title">
        Как установить наши приложения (
        <a
          onClick={() => {
            navigate("/xcalc");
          }}
        >
          поиск
        </a>
        ,{" "}
        <a
          onClick={() => {
            navigate("/deals");
          }}
        >
          актуальные скидки
        </a>
        ,{" "}
        <a
          onClick={() => {
            navigate("/donate");
          }}
        >
          игровая валюта
        </a>
        ) себе на телефон:
      </h2>

      <div class="acor-container">
        <input type="checkbox" name="chacor" id="chacor1" />
        <label for="chacor1">Инструкция для Android устройств</label>
        <div class="acor-body">
          <div>
            <div className="instruction__text">
              <p>1. Откройте браузер Chrome или Opera </p>
              <p>
                2. Перейдите по ссылке{" "}
                <a
                  href="https://xbogames.ru/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://xbogames.ru/
                </a>{" "}
              </p>
              <p>3. Откройте меню браузера</p>
              <div className="instruction__img-wrapper">
                <div className="instruction__img">
                  <img src={android_1} alt="android_1" />
                </div>
              </div>
              <p>4. Нажмите "Добавить на гл. экран"</p>
              <div className="instruction__img-wrapper">
                <div className="instruction__img">
                  <img src={android_2} alt="android_1" />
                </div>
              </div>
              <p>5. После установки, приложение появится у вас в телефоне</p>
              <div className="instruction__img-wrapper">
                <div className="instruction__img">
                  <img src={android_3} alt="android_1" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <input type="checkbox" name="chacor" id="chacor2" />
        <label for="chacor2">Инструкция для iOS устройств</label>
        <div class="acor-body">
          <div className="instruction__text">
            <p>1. Откройте браузер Safari </p>
            <p>
              2. Перейдите по ссылке{" "}
              <a
                href="https://xbogames.ru/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://xbogames.ru/
              </a>{" "}
            </p>
            <p>3. Откройте меню браузера</p>
            <div className="instruction__img-wrapper">
              <div className="instruction__img">
                <img src={ios_1} alt="ios_1" />
              </div>
            </div>
            <p>4. Нажмите "На экран "Домой"</p>
            <div className="instruction__img-wrapper">
              <div className="instruction__img">
                <img src={ios_2} alt="ios_2" />
              </div>
            </div>

            <p>5. После установки, приложение появится у вас в телефоне</p>
            <div className="instruction__img-wrapper">
              <div className="instruction__img">
                <img src={ios_3} alt="ios_1" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="instruction__img">
        <img src={xbgall} alt="primer" />
      </div>
    </div>
  );
};

export default InstructionsPage;
