import "./styles.css";

import { useEffect } from "react";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ButtonHead = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log(location.pathname);
  }, []);

  return (
    <div className="wrapper__btn-head">
      {location.pathname == "/xcalc" ? (
        <button
          class="custom-btn btn-16 btn-activ"
          onClick={() => {
            navigate("/xcalc");
          }}
        >
          Поиск
        </button>
      ) : (
        <button
          class="custom-btn btn-16"
          onClick={() => {
            navigate("/xcalc");
          }}
        >
          Поиск
        </button>
      )}

      {location.pathname == "/deals" ? (
        <button
          class="custom-btn btn-16 btn-activ"
          onClick={() => {
            navigate("/deals");
          }}
        >
          Скидки
        </button>
      ) : (
        <button
          class="custom-btn btn-16"
          onClick={() => {
            navigate("/deals");
          }}
        >
          Скидки
        </button>
      )}

      {location.pathname == "/donate" ? (
        <button
          class="custom-btn btn-16 btn-activ"
          onClick={() => {
            navigate("/donate");
          }}
        >
          Игровая валюта
        </button>
      ) : (
        <button
          class="custom-btn btn-16"
          onClick={() => {
            navigate("/donate");
          }}
        >
          Игровая валюта
        </button>
      )}

      {location.pathname == "/faq" ? (
        <button
          class="custom-btn btn-16 btn-activ"
          onClick={() => {
            navigate("/faq");
          }}
        >
          FAQ
        </button>
      ) : (
        <button
          class="custom-btn btn-16"
          onClick={() => {
            navigate("/faq");
          }}
        >
          FAQ
        </button>
      )}
    </div>
  );
};

export default ButtonHead;
