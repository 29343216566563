import "./styles.css";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../img/logo.png";
import gridImg from "../../../img/control/listing-view-grid.svg";
import rowImg from "../../../img/control/listing-view-row.svg";

import {
  getDealsGames,
  getNewGames,
  getTopPaidGames,
} from "../../../features/DealsSlice/DealsSlice";
import { getCarrencyCurSu } from "../../../features/CarrencyCurSuSlice/CarrencyCurSuSlice";
import DealsItem from "../DealsItem";
import { BarLoader, ClockLoader } from "react-spinners";
import DealsItemGames from "../DealsItemGames";
import ButtonHead from "../../buttonHead";
const DealsHomeGames = () => {
  const dispatch = useDispatch();

  const dealsGamesListId = useSelector((state) => state.deals.dealsGamesList);
  const isFetchingDeals = useSelector((state) => state.deals.isFetchingDeals);

  const [sorting, setSorting] = useState("all");
  let sort = [];

  const [sortingArr, setSortingArr] = useState([]);

  const [list, setList] = useState(false);

  const [rowGrid, setRowGrid] = useState(true);

  useEffect(() => {
    // console.log(dealsGamesListId);
    dispatch(getDealsGames());
  }, []);

  useEffect(() => {
    // console.log(dealsGamesListId);
    // sort = [...dealsGamesListId].sort(byField("name"))
    if (sorting === "alphabet") {
      setSortingArr([...dealsGamesListId].sort(byField("name")));
      // console.log(sortingArr);
    }
    if (sorting === "price") {
      setSortingArr([...dealsGamesListId].sort(byField("price")));
      // console.log(sortingArr);
    }

    if (sorting === "priceUsa") {
      setSortingArr([...dealsGamesListId].sort(byField("priceUsa")));
      // console.log(sortingArr);
    }
  }, [sorting]);

  function byField(field) {
    return (a, b) => (a[field] > b[field] ? 1 : -1);
  }

  const GridRow = () => {
    setList((prev) => !prev);
    setRowGrid((prev) => !prev);
  };

  return (
    <>
      {/* <ButtonHead /> */}
      <div className="gpoup-information__text" style={{ margin: "20px" }}>
        Актуальные скидки группы
        {/* <div> {`Всего ${dealsGamesListId.length} игр и dlc`}</div> */}
        <div className="gpoup-information-deals">
          <img className="gpoup-information-deals__logo" src={logo} />
          <a
            className="gpoup-information__title"
            href="https://vk.com/xbogames"
            target="_blank"
            rel="noopener noreferrer"
          >
            XBOGames
          </a>
        </div>
      </div>

      {isFetchingDeals && (
        <div className="control">
          <div className="sorting">
            <select
              className="select-css"
              onChange={(e) => {
                setSorting(e.target.value);
              }}
            >
              <option value="all">По умолчанию</option>
              <option value="alphabet">По алфавиту</option>
              <option value="price">По цене (новый акк)</option>
              <option value="priceUsa">По цене (ваш акк)</option>
            </select>
          </div>

          <div className="control-list">
            <img
              onClick={() => GridRow()}
              src={rowImg}
              alt="row"
              className={`control__img ${
                rowGrid ? "control__img--disabled" : " "
              }`}
            />
            <img
              onClick={() => GridRow()}
              src={gridImg}
              alt="grid"
              className={`control__img ${
                rowGrid ? "" : "control__img--disabled "
              }`}
            />
          </div>
        </div>
      )}

      {dealsGamesListId.length > 0 ? (
        <>
          {sorting === "all" && (
            <>
              {isFetchingDeals === true ? (
                <div className={`deals ${list ? "deals__list" : " "}`}>
                  {dealsGamesListId.map((item) => (
                    <DealsItemGames key={item.gameId} item={item} list={list} />
                  ))}
                </div>
              ) : (
                <div className="deals deals-spinner">
                  {" "}
                  <ClockLoader color="#36d67b" />{" "}
                </div>
              )}
            </>
          )}

          {sorting === "alphabet" && (
            <>
              {isFetchingDeals === true ? (
                <div className={`deals ${list ? "deals__list" : " "}`}>
                  {sortingArr.map((item) => (
                    <DealsItemGames key={item.gameId} item={item} list={list} />
                  ))}
                </div>
              ) : (
                <div className="deals deals-spinner">
                  {" "}
                  <ClockLoader color="#36d67b" />{" "}
                </div>
              )}
            </>
          )}

          {sorting === "price" && (
            <>
              {isFetchingDeals === true ? (
                <div className={`deals ${list ? "deals__list" : " "}`}>
                  {sortingArr.map((item) => (
                    <DealsItemGames key={item.gameId} item={item} list={list} />
                  ))}
                </div>
              ) : (
                <div className="deals deals-spinner">
                  {" "}
                  <ClockLoader color="#36d67b" />{" "}
                </div>
              )}
            </>
          )}

          {sorting === "priceUsa" && (
            <>
              {isFetchingDeals === true ? (
                <div className={`deals ${list ? "deals__list" : " "}`}>
                  {sortingArr.map((item) => (
                    <DealsItemGames key={item.gameId} item={item} list={list} />
                  ))}
                </div>
              ) : (
                <div className="deals deals-spinner">
                  {" "}
                  <ClockLoader color="#36d67b" />{" "}
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="error-arr">Обновите страницу через 10-15 минут</div>
      )}
    </>
  );
};

export default DealsHomeGames;
