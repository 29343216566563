import "./styles.css";
import { useState, useEffect } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../../img/logo.png";
import { ClipLoader } from "react-spinners";

const CarrencyUser = () => {
  const dispatch = useDispatch();

  const carrencyArCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyArCurSu
  );

  const carrencyTrCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyTrCurSu
  );

  const carrencyUahCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyUahCurSu
  );

  // const carrencyEgCurSu = useSelector(
  //   (state) => state.carrencyCurSu.carrencyEgCurSu
  // );
  // const carrencyNgCurSu = useSelector(
  //   (state) => state.carrencyCurSu.carrencyNgCurSu
  // );
  const carrencyRuCurSu = useSelector(
    (state) => state.carrencyCurSu.carrencyRuCurSu
  );

  //Обычные цены
  const searchGamePriceAr = useSelector(
    (state) => state.search.searchGamePriceAr
  );
  const searchGamePriceTr = useSelector(
    (state) => state.search.searchGamePriceTr
  );

  const searchGamePriceUsa = useSelector(
    (state) => state.search.searchGamePriceUsa
  );

  const searchGamePriceUah = useSelector(
    (state) => state.search.searchGamePriceUah
  );
  // const searchGamePriceEg = useSelector(
  //   (state) => state.search.searchGamePriceEg
  // );
  // const searchGamePriceNg = useSelector(
  //   (state) => state.search.searchGamePriceNg
  // );

  // Цены со скидками
  const searchGamePriceArSale = useSelector(
    (state) => state.search.searchGamePriceArSale
  );
  const searchGamePriceTrSale = useSelector(
    (state) => state.search.searchGamePriceTrSale
  );

  const searchGamePriceUsaSale = useSelector(
    (state) => state.search.searchGamePriceUsaSale
  );

  const searchGamePriceUahSale = useSelector(
    (state) => state.search.searchGamePriceUahSale
  );
  // const searchGamePriceEgSale = useSelector(
  //   (state) => state.search.searchGamePriceEgSale
  // );

  // const searchGamePriceNgSale = useSelector(
  //   (state) => state.search.searchGamePriceNgSale
  // );
  // =====
  const searchProductType = useSelector(
    (state) => state.search.searchProductType
  );

  const whatXbox = useSelector((state) => state.search.whatXbox);

  const [markArr, setMarkArr] = useState([]); // массив цен

  const [markArrNewAcc, setMarkArrNewAcc] = useState([]); // массив цен на новый акк

  const [markup, setMurkUp] = useState(300); // цена в рублях в аргентине

  const [priceAr, setPriceAr] = useState(0); // цена в рублях в аргентине
  const [priceTr, setPriceTr] = useState(0); // цена в рублях в турции
  const [priceUsa, setPriceUsa] = useState(0); // цена в рублях в США
  // const [priceUah, setPriceUah] = useState(0); // цена в рублях в UKR
  // const [priceEg, setPriceEg] = useState(0); // цена в рублях в египет
  // const [priceNg, setPriceNg] = useState(0); // цена в рублях в nigeria

  const [priceArSale, setPriceArSale] = useState(0); // цена в рублях в аргентине на скидке
  const [priceTrSale, setPriceTrSale] = useState(0); // цена в рублях в турции на скидке
  const [priceUsaSale, setPriceUsaSale] = useState(0); // цена в рублях в США на скидке
  // const [priceUahSale, setPriceUahSale] = useState(0); // цена в рублях в UKR на скидке
  // const [priceEgSale, setPriceEgSale] = useState(0); // цена в рублях в египте на скидке
  // const [priceNgSale, setPriceNgSale] = useState(0); // цена в рублях в nigeria на скидке

  const [priceArDt, setPriceArDt] = useState(0); // цена в рублях в аргентине c накруткой DreamTeam
  const [priceTrDt, setPriceTrDt] = useState(0); // цена в рублях в турции c накруткой DreamTeam
  const [priceUsaDt, setPriceUsaDt] = useState(0); // цена в рублях в США c накруткой DreamTeam
  // const [priceUahDt, setPriceUahDt] = useState(0); // цена в рублях в UKR c накруткой DreamTeam
  // const [priceEgDt, setPriceEgDt] = useState(0); // цена в рублях в egypet c накруткой DreamTeam
  // const [priceNgDt, setPriceNgDt] = useState(0); // цена в рублях в nigeria c накруткой DreamTeam

  const [priceArDtSale, setPriceArDtSale] = useState(0); // цена в рублях в аргентине c накруткой DreamTeam на скидке
  const [priceTrDtSale, setPriceTrDtSale] = useState(0); // цена в рублях в турции c накруткой DreamTeam на скидке
  const [priceUsaDtSale, setPriceUsaDtSale] = useState(0); // цена в рублях в США c накруткой DreamTeam на скидке
  // const [priceUahDtSale, setPriceUahDtSale] = useState(0); // цена в рублях в UKR c накруткой DreamTeam на скидке
  // const [priceEgDtSale, setPriceEgDtSale] = useState(0); // цена в рублях в egypet c накруткой DreamTeam на скидке
  // const [priceNgDtSale, setPriceNgDtSale] = useState(0); // цена в рублях в egypet c накруткой DreamTeam на скидке

  const [isSwitchCalc, setIsSwitchCalc] = useState(false);

  const [isSwitchCalc1, setIsSwitchCalc1] = useState(false);

  const priceCalculationConv = () => {
    // console.log("чык-чырык");

    // console.log(carrencyNgCurSu);
    // console.log(searchGamePriceNg);

    setPriceAr(searchGamePriceAr / (carrencyArCurSu / carrencyRuCurSu)); // цена в рублях в аргентине
    setPriceTr(searchGamePriceTr / (carrencyTrCurSu / carrencyRuCurSu)); // цена в рублях в турции
    setPriceUsa(searchGamePriceUsa * carrencyRuCurSu); // цена в рублях в США
    // setPriceUah(searchGamePriceUah / (carrencyUahCurSu / carrencyRuCurSu)); // цена в рублях в UKR
    // setPriceEg(searchGamePriceEg / (carrencyEgCurSu / carrencyRuCurSu)); // цена в рублях в egypet
    // setPriceNg(searchGamePriceNg / (carrencyNgCurSu / carrencyRuCurSu)); // цена в рублях в nigeria
    // console.log(carrencyRuCurSu / carrencyUahCurSu);
    setPriceArSale(searchGamePriceArSale / (carrencyArCurSu / carrencyRuCurSu)); // цена в рублях в аргентине на скидке
    setPriceTrSale(searchGamePriceTrSale / (carrencyTrCurSu / carrencyRuCurSu)); // цена в рублях в турции на скидке
    setPriceUsaSale(searchGamePriceUsaSale * carrencyRuCurSu); // цена в рублях в США на скидке
    // setPriceUahSale(
    //   searchGamePriceUahSale / (carrencyUahCurSu / carrencyRuCurSu)
    // ); // цена в рублях в UKR на скидке

    // setPriceEgSale(searchGamePriceEgSale / (carrencyEgCurSu / carrencyRuCurSu)); // цена в рублях в egypet на скидке
    // setPriceNgSale(searchGamePriceNgSale / (carrencyNgCurSu / carrencyRuCurSu)); // цена в рублях в nigeria на скидке
  };

  const priceCalculationDtDonate = () => {
    const arrSum = [];
    // if (searchProductType === "Consumable" || whatXbox === true) {
    if (searchProductType === "Consumable") {
      setMurkUp(560);
    } else setMurkUp(300);

    if (priceAr !== 0) {
      setPriceArDt(
        priceAr * 1.78 + 200 + markup + (priceAr * 1.78 + 200 + markup) * 0.13
      );
    } else setPriceArDt(999999);

    if (priceTr !== 0) {
      setPriceTrDt(
        priceTr +
          priceTr * 0.1 +
          400 +
          markup +
          (priceTr + priceTr * 0.1 + 400 + markup) * 0.2
      );
    } else setPriceTrDt(999999);
    arrSum.push({ price: priceTrDt, cantry: "tur" });

    if (priceUsa !== 0) {
      setPriceUsaDt(
        priceUsa + 100 + markup + (priceUsa + 100 + markup) * 0.1 + 200
      );
    } else setPriceUsaDt(999999);
    arrSum.push({ price: priceUsaDt, cantry: "usa" });

    // ===========================Украина
    // if (priceUah !== 0) {
    //   setPriceUahDt(
    //     priceUah + 100 + markup + (priceUah + 100 + markup) * 0.2 + 200
    //   );
    // } else setPriceUahDt(999999);
    // arrSum.push({ price: priceUahDt, cantry: "urk" });
    // ===========================Украина

    // if (priceEg !== 0) {
    //   setPriceEgDt(priceEg + priceEg * 0.16 + 200 + markup);
    // } else setPriceEgDt(999999);

    // if (priceNg !== 0) {
    //   setPriceNgDt(priceNg + priceNg * 0.16 + 200 + markup);
    // } else setPriceNgDt(999999);

    // считаем на скидках
    if (priceArSale !== 0) {
      setPriceArDtSale(
        priceArSale * 1.78 +
          200 +
          markup +
          (priceArSale * 1.78 + 200 + markup) * 0.13
      );
    } else setPriceArDtSale(999999);

    if (priceTrSale !== 0) {
      setPriceTrDtSale(
        priceTrSale +
          priceTrSale * 0.1 +
          400 +
          markup +
          (priceTrSale + priceTrSale * 0.1 + 400 + markup) * 0.2
      );
    } else setPriceTrDtSale(999999);

    if (priceUsaSale !== 0) {
      setPriceUsaDtSale(
        priceUsaSale + 100 + markup + (priceUsaSale + 100 + markup) * 0.1 + 200
      );
    } else setPriceUsaDtSale(999999);

    // ===========================Украина

    // if (priceUahSale !== 0) {
    //   setPriceUahDtSale(
    //     priceUahSale + 100 + markup + (priceUahSale + 100 + markup) * 0.2 + 200
    //   );
    // } else setPriceUahDtSale(999999);

    // ===========================Украина

    // if (priceEgSale !== 0) {
    //   setPriceEgDtSale(priceEgSale + priceEgSale * 0.16 + 200 + markup);
    // } else setPriceEgDtSale(999999);

    // if (priceNgSale !== 0) {
    //   setPriceNgDtSale(priceNgSale + priceNgSale * 0.16 + 200 + markup);
    // } else setPriceNgDtSale(999999);
    // ==========
    // console.log(+priceNgDt);
    // console.log("Египет" + priceEgDt);
    // console.log("Ничегия" + priceNgSale);
    // console.log(priceUsaDt);
  };

  useEffect(() => {
    priceCalculationConv();

    setTimeout(() => {
      setIsSwitchCalc(true);
    }, 1000);

    setTimeout(() => {
      setIsSwitchCalc1(true);
    }, 3000);
  }, []);

  useEffect(() => {
    priceCalculationDtDonate();
    // console.log(priceUsaDt);
    // setTimeout(() => {
    //   console.log(priceUsaDt);
    //   console.log(priceUsaDtSale);
    // }, 1000);
  }, [isSwitchCalc]);

  useEffect(() => {
    setMarkArr([
      // {
      //   id: 1,
      //   price: priceUahDt,
      //   priceSale: priceUahDtSale,
      //   text: "рублей.",
      //   country: "ukr",
      // },
      {
        id: 2,
        price: priceUsaDt,
        priceSale: priceUsaDtSale,
        text: "рублей",
        country: "usa",
      },
      // { price: priceTrDt, text: "рублей", contry: "try" },
    ]);

    setMarkArrNewAcc([
      // {
      //   id: 1,
      //   price: priceUahDt,
      //   priceSale: priceUahDtSale,
      //   text: "рублей.",
      //   country: "ukr",
      // },
      {
        id: 2,
        price: priceUsaDt,
        priceSale: priceUsaDtSale,
        text: "рублей",
        country: "usa",
      },
      {
        id: 3,
        price: priceTrDt,
        priceSale: priceTrDtSale,
        text: "рублей",
        country: "try",
      },
    ]);
    // console.log(markArr);
  }, [isSwitchCalc1]);

  return (
    <>
      <div className="game-info__price">
        {isSwitchCalc1 !== true ? (
          <div className="game-info__price-text">
            <ClipLoader color="#285f8f" />
          </div>
        ) : (
          <div>
            {priceTrDt > 99999 ? (
              <div className="game-info__price-text">
                Товар недоступен для покупки
              </div>
            ) : (
              <>
                {markArrNewAcc
                  .sort((a, b) => (a.price > b.price ? 1 : -1))
                  .slice(0, 1)
                  .map((item) => (
                    <div key={item.id}>
                      <div className="game-info__price-text-sub">
                        На новый аккаунт
                      </div>
                      <span className="game-info__price-text">
                        {Math.ceil(item.price / 10) * 10} {item.text}
                      </span>
                      {item.priceSale < item.price && item.country == "try" ? (
                        <div>
                          <div className="game-info__price-text-sub">
                            При наличии подписки
                          </div>
                          <span className="game-info__price-text">
                            {Math.ceil(item.priceSale / 10) * 10} {item.text}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
              </>
            )}
          </div>
        )}

        {isSwitchCalc1 !== true ? (
          ""
        ) : (
          <div>
            {priceUsaDt > 99999 ? (
              <div className="game-info__price-text"></div>
            ) : (
              <>
                {markArr
                  .sort((a, b) => (a.price > b.price ? 1 : -1))
                  .slice(0, 1)
                  .map((item) => (
                    <div key={item.id}>
                      <div className="game-info__price-text-sub">
                        На ваш аккаунт
                      </div>
                      <span className="game-info__price-text">
                        {Math.ceil(item.price / 10) * 10} {item.text}
                      </span>
                      {item.priceSale < item.price ? (
                        <div>
                          <div className="game-info__price-text-sub">
                            При наличии подписки
                          </div>
                          <span className="game-info__price-text">
                            {Math.ceil(item.priceSale / 10) * 10} {item.text}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  ))}
              </>
            )}
          </div>
        )}
      </div>

      <div className="gpoup-information">
        <img className="gpoup-information__logo" src={logo} />
        <a
          className="gpoup-information__title"
          href="https://vk.com/xbogames"
          target="_blank"
          rel="noopener noreferrer"
        >
          XBOGames
        </a>
      </div>
    </>
  );
};

export default CarrencyUser;

////Старый расчет

// const priceCalculationDtDonate = () => {
//   if (searchProductType === "Consumable" || whatXbox === true) {
//     const markup = 400;

//     if (priceAr !== 0) {
//       setPriceArDt(priceAr + priceAr * 0.15 + 300 + markup);
//     } else setPriceArDt(999999);

//     if (priceTr !== 0) {
//       setPriceTrDt(priceTr + priceTr * 0.15 + 300 + markup);
//     } else setPriceTrDt(999999);
//   } else {
//     priceCalculationDt();
//   }
// };

// const priceCalculationDt = () => {
//   const markup = 350;
//   // console.log("Я в новой функции");
//   // console.log(searchProductType);

//   if (priceAr !== 0) {
//     if (priceAr < 1000) {
//       setPriceArDt(priceAr + priceAr * 0.15 + 150 + markup);
//     } else if (priceAr < 1815) {
//       const sum = priceAr + priceAr * 0.15;
//       setPriceArDt(priceAr + priceAr * 0.15 + sum * 0.12 + markup);
//     } else if (priceAr > 1815) {
//       const sum = priceAr + priceAr * 0.15;
//       setPriceArDt(priceAr + priceAr * 0.15 + sum + 250 + markup);
//     }
//   } else setPriceArDt(999999);

//   if (priceTr !== 0) {
//     if (priceTr < 1000) {
//       setPriceTrDt(priceTr + priceTr * 0.15 + 150 + markup);
//     } else if (priceTr < 1815) {
//       const sum = priceTr + priceTr * 0.15;
//       setPriceTrDt(priceTr + priceTr * 0.15 + sum * 0.12 + markup);
//     } else if (priceTr > 1815) {
//       const sum = priceTr + priceTr * 0.15;
//       setPriceTrDt(priceTr + priceTr * 0.15 + sum + 250 + markup);
//     }
//   } else setPriceTrDt(999999);
// };

//////===========старый вывод через сравнения

// <>
// <div className="game-info__price">
//   {isSwitchCalc !== true && isSwitchCalc1 !== true ? (
//     <div className="game-info__price-text">
//       <ClipLoader color="#285f8f" />
//     </div>
//   ) : (
//     <div>
//       {priceTrDt > 99999 ? (
//         <div className="game-info__price-text">
//           Товар недоступен для покупки
//         </div>
//       ) : (
//         <div>
//           <div className="game-info__price-text-sub">
//             На новом аккаунте
//           </div>

//           <span className="game-info__price-text">
//             <div>
//               {" "}
//               {priceTrDt > priceUsaDt ? (
//                 <>{Math.ceil(priceUsaDt / 10) * 10} рублей</>
//               ) : (
//                 <>{Math.ceil(priceTrDt / 10) * 10} рублей</>
//               )}
//             </div>
//             {/* {Math.ceil(priceTrDt / 10) * 10} рублей{" "} */}
//           </span>
//         </div>
//       )}
//       {priceTrDtSale > 99999 ||
//       priceTrDtSale >= priceTrDt ||
//       priceTrDtSale >= priceUsaDtSale ? (
//         ""
//       ) : (
//         <div>
//           <div className="game-info__price-text-sub">
//             При наличии подписки
//           </div>
//           <span className="game-info__price-text">
//             {Math.ceil(priceTrDtSale / 10) * 10} рублей{" "}
//           </span>
//         </div>
//       )}
//     </div>
//   )}

//   {isSwitchCalc !== true && isSwitchCalc1 !== true ? (
//     ""
//   ) : (
//     <div>
//       {priceUsaDt > 99999 && priceUahDt > 99999 ? (
//         <div className="game-info__price-text"></div>
//       ) : (
//         <>
//           <div className="game-info__price-text-sub">На ваш аккаунт</div>
//           {priceUsaDt > priceUahDt ? (
//             <span className="game-info__price-text">
//               {/* {Math.ceil(priceUsaDt / 10) * 10} рублей{" "} */}
//               {Math.ceil(priceUahDt / 10) * 10} рублей
//               <span style={{ fontSize: 10 }}>I</span>
//             </span>
//           ) : (
//             <span className="game-info__price-text">
//               {Math.ceil(priceUsaDt / 10) * 10} рублей{" "}
//               {markArr
//                 .sort((a, b) => (a.price > b.price ? 1 : -1))
//                 .slice(0, 1)
//                 .map((item) => (
//                   <div>{Math.ceil(item.price / 10) * 10} </div>
//                 ))}
//               {/* {markArr.map((item) => (
//                 <div>{item.price}</div>
//               ))} */}
//               {/* {Math.ceil(priceUahDt / 10) * 10} ukr{" "} */}
//             </span>
//           )}
//         </>
//       )}

//       {priceUsaDtSale > 99999 || priceUsaDtSale >= priceUsaDt ? (
//         ""
//       ) : (
//         <>
//           {" "}
//           <div className="game-info__price-text-sub">
//             При наличии подписки
//           </div>
//           <span className="game-info__price-text">
//             {Math.ceil(priceUsaDtSale / 10) * 10} рублей{" "}
//           </span>
//         </>
//       )}
//     </div>
//   )}
// </div>

// <div className="gpoup-information">
//   <img className="gpoup-information__logo" src={logo} />
//   <a
//     className="gpoup-information__title"
//     href="https://vk.com/xbogames"
//     target="_blank"
//     rel="noopener noreferrer"
//   >
//     XBOGames
//   </a>
// </div>
// </>
